// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import { device } from 'components/device';

import FeaturedMarketing from 'components/featuredMarketing';
import WaitingTime from 'components/promptService/waitingTime';
import TextSection from 'components/units/unitPages/details/TextSection';
import OutDoor from 'components/units/unitPages/unit/outdoorService';

// Images
import firstBlob from 'images/blobs/Slime_LightBlue.webp';
import tirdBlob from 'images/blobs/Slime_Lilac.webp';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.mobile} {
    margin-top: -3rem;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  @media ${device.laptop} {
    display: ${props => props.display};
    width: ${props => props.width};
  }
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: ${props =>
      props.isCDICardio ? 'column' : 'column-reverse'};
  }
`;

const Teste = styled.div`
  display: flex;
  width: ${props => props.cards && '50%'};
  flex-direction: ${props => props.cards && 'column'};

  @media ${device.laptop} {
    flex-direction: column-reverse;
    section {
      width: 100%;
    }
  }

  @media ${device.tablet} {
    width: ${props => props.cards && '100%'};
    margin-top: ${props => props.noMargin && '0 !important'};
  }
`;

const GridMainServices = styled.div`

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding: 1.25rem;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
    background: #f4f5f7;
  }
`;

const getMarkdown = (
  page,
  fragmentId,
  isMarkdown = false,
  assets = false,
  assetsMultiple = false
) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else if (assetsMultiple) {
    return findFragment.assetpickerMultiple;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / -2'}>
      <FeaturedMarketing
        markdown={getMarkdown(page, 'cklqxv7jszpuj0b78e8vwczs2', true)}
        fragments={getMarkdown(page, 'cklqxv7jszpuj0b78e8vwczs2')}
        breadCrumb={getMarkdown(page, 'cklqy7elc053d0976mw1ikqbi', true)}
        asset={getMarkdown(page, 'cklqxv7jszpuj0b78e8vwczs2', false, true)}
        assetMultiple={getMarkdown(
          page,
          'cklqxv7jszpuj0b78e8vwczs2',
          false,
          false,
          true
        )}
        isCarouselPhotos
        wazeProntoAtendimento
        isPA
        containerMarginBottomLap="1.875rem"
        containerNoPaddingTopLap
        containerNoBorderBottomLap
        isPediatric
        firstBlobTransformDesk="translate(-20%, -5%) rotate(6deg)"
        blobs={{ firstBlob, tirdBlob }}
        boxInfoParagraphImgTransformDesk="translate(0, 85%)"
        boxInfoPaddingRImgDesk="1rem"
        boxInfoPaddingRImgDesk2="0.2rem"
        titlePaddingTopLap="2.0625rem"
        containerMarginBottomLap="unset"
        containerMarginBottomTab="0"
        boxInfoHasNoPaddingMob
        boxInfoPaddingRImgLap="unset"
        boxInfoParagraphImgTransformLap="unset"
        boxInfoParagraphImgWidth="unset"
        boxInfoParagraphImgHeight="unset"
        boxInfoPaddingLeftEmDesk="2.12rem"
        boxInfoHeightLap="auto"
      />
    </Part>
    <Container isCDICardio cards>
      <Part gridColumn={'1 / -1'}>
        <Part gridColumn={'1 / span 5'} width={'100%'}>
          <Teste noMargin>
            <TextSection
              markdown={getMarkdown(page, 'cklqyxjy8ztr90974g2tb6exa', true)}
              gridArea="ABT"
              isJustCards
              isAbout
              isShowing
              isCDICardio
              containerWidth="122%"
              
            />
            <GridMainServices>
              <WaitingTime isJustCards="Pediatria" noPadding />
            </GridMainServices>
          </Teste>
        </Part>
      </Part>
    </Container>
    <Part gridColumn={'2 / -2'}>
      <OutDoor
        pageName="Bela Vista"
        url="/unidades/bela-vista/detalhes"
        Outdor
      />
    </Part>
  </GridContainer>
);

const CardiologyRoom = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'cklqxv7jszpuj0b78e8vwczs2', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cklqxt70ozjio097428lf1d5s" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                  assetpickerMultiple {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <CardiologyRoom page={response.gcms.site.pages} />;
      }}
    />
  );
};
